import React from 'react';
import Layout from '../components/layout';
import { Router } from '@reach/router';
import FlyLegal from '@components/FlyLegal';

const FlyLegalPage = ({ location }) => {
  const path = location.pathname;

  const translations = [
    { path: `${path}`, polylang_current_lang: 'en_US' },
    { path: path, polylang_current_lang: 'es_ES' },
    { path: path, polylang_current_lang: 'pt_BR' },
    // { path: path, polylang_current_lang: 'it_IT' },
  ];

  return (
    <Layout
      SearchHeader={false}
      translations={translations}
      useTranslationsPath={false}
      isPrivate={false}
    >
      <Router basepath="/flysafe">
        <FlyLegal default path="/" />
      </Router>
    </Layout>
  );
};

export default FlyLegalPage;
