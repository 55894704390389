import React from 'react';
import * as Styled from './FlyLegal.styles.js';
import FormFlyLegal from '@components/FormFlyLegal';
import { translate as t } from '@utils/translate';

const FlySafe = () => {
  const TEXTS = {
    title: t('flysafeTitle'),
    content: t('flysafeText'),
    subtitle: t('aircraftSearch'),
  };

  return (
    <Styled.FlyLegalWrapper>
      <Styled.Container>
        <Styled.Content>
          <Styled.Title>{TEXTS.title}</Styled.Title>
          <Styled.Subtitle>{TEXTS.subtitle}</Styled.Subtitle>

          <FormFlyLegal />
          <Styled.Text>{TEXTS.content}</Styled.Text>
        </Styled.Content>
      </Styled.Container>
      <Styled.Retangle />
    </Styled.FlyLegalWrapper>
  );
};

export default FlySafe;
