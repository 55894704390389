import styled, { css, keyframes } from 'styled-components';
import InputMask from 'react-input-mask';

const loading = (transformInitialParams, transformFinalParams) => keyframes`
from {
  transform: ${transformInitialParams};
} to {
 transform: ${transformFinalParams};
}
`;

const Spinner = styled.div`
  position: relative;
  display: flex;
  width: 50px;
  height: 50px;

  .spinner_green {
    width: 40px;
    height: 40px;
    object-fit: contain;
    position: absolute;
    top: 5px;
    left: 8px;
    transform-origin: center;
    animation: ${loading('rotate(0deg)', 'rotate(360deg)')} 1s linear infinite
      reverse;
  }

  .spinner_white {
    object-fit: contain;
    position: absolute;
    top: 12px;
    left: 15px;
    transform: scale(0.7);
    transform-origin: center;
    animation: ${loading(
        'rotate(0deg) scale(0.8)',
        'rotate(360deg) scale(0.8)'
      )}
      1s linear infinite;
  }
`;

const translateBottom = keyframes`
  from {
    transform: translateY(-150%);
  } to {
    transform: translateY(0);
  }
`;

const ErrorForm = styled.span`
  font-size: 0.8rem;
  line-height: 16px;
  margin-top: 4px;
  color: #ff6968;
  position: absolute;
  bottom: -20px;
  left: 20px;
  animation: ${translateBottom} 0.3s ease-in-out forwards;
`;

const InputToggleContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;

  span {
    font-weight: 500;
    font-size: 20px;
    color: #45454580;
    margin-left: 0px;
    display: block;
  }
`;

const Title = styled.h1``;

const IconButton = styled.img`
  @media screen and (max-width: 510px) {
    display: none;
  }
`;

const Button = styled.button`
  width: 100%;
  max-width: 250px;
  height: 50px;
  border-radius: 50px;
  background-color: #33e0ce;
  border: none;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  transition: all 0.1s ease-in-out;
  @media screen and (min-width: 1024px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 510px) {
    flex: 0;
    flex-basis: 140px;

    .searching_label {
      display: none;
    }
  }

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  &:active {
    transform: ${props => (!props.disabled ? ' translateY(8px)' : null)};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  margin-bottom: 40px;
  position: relative;
  margin-bottom: 40px;

  @media screen and (min-width: 1024px) {
    margin-bottom: 0px;
  }
`;

const Label = styled.label`
  display: flex;
  height: 70px;
  width: 100%;
  background-color: white;
  border-radius: 50px;
  z-index: 2;
  position: relative;
`;

const Icon = styled.img`
  position: absolute;
  top: 18px;
  left: 22px;
  object-fit: contain;
  height: 30px;
`;

const Input = styled(InputMask)`
  width: 100%;
  height: 100%;
  border-radius: 50px;
  transition: all 0.2s ease-in-out;
  outline: none;
  border: none;
  padding: 10px 10px 10px 82px;
  color: #45454580;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1rem;

  @media screen and (min-width: 1024px) {
    font-size: 1.2rem;
  }
`;

const ContainerForm = styled.div`
  background-color: #fff;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
`;

const Feedback = styled.div`
  color: black;
  padding: 0 20px 20px 20px;

  @media screen and (min-width: 1024px) {
    padding: 40px;
  }
`;

const FeedbackDate = styled.div`
  font-size: 1rem;
  color: #174c57;
  opacity: 0.5;
`;

const FeedbackStatus = styled.div`
  border-radius: 50px;
  border: ${props => `2px solid ${props.isAuthorized ? '#00bfa5' : '#FF6968'}`};
  padding: 10px;
  display: inline-block;
  margin: 10px 0;
  font-size: 1rem;

  @media screen and (min-width: 1024px) {
    font-size: 1.5rem;
  }
`;

const FeedbackLabel = styled.div`
  margin-bottom: 10px;
  font-size: 1rem;
  color: #174c5788;
  font-weight: 500;

  font-size: 1rem;
`;

const FeedbackValue = styled.div`
  font-weight: 500;
  color: #174c57;
  margin: 10px 0 20px 0;
  opacity: 1;

  font-size: 1rem;

  @media screen and (min-width: 1024px) {
    font-size: 1.5rem;
  }
`;

const Scale = styled.div`
  transform: scale(${props => props.value});
`;

const Text = styled.div`
  color: #adadad;
  margin-right: 40px;
`;

const Operator = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 666px) {
    position: absolute;
    top: calc(100% - 40px);
    padding-top: 40px;
    background-color: #f9f9f9;
    border-radius: 0 15px 15px 15px;
  }
`;

const StatusContainer = styled.div`
  display: flex;
`;

export {
  Title,
  Input,
  Label,
  Icon,
  IconButton,
  Button,
  InputToggleContainer,
  ContainerForm,
  Feedback,
  FeedbackDate,
  FeedbackStatus,
  FeedbackLabel,
  FeedbackValue,
  ErrorForm,
  FlexContainer,
  Spinner,
  Scale,
  Text,
  Operator,
  StatusContainer,
};
