import React, { useEffect, useState } from 'react';
import * as Styled from './FormFlyLegal.styles';
import { useForm, Controller } from 'react-hook-form';
import { format } from 'date-fns';
import { enUS, es, ptBR } from 'date-fns/locale';

// components
import InputToggle from '@components/InputToggle';

// services
import {
  checkAircraftAuthorization,
  checkCompanyAuthorization,
} from '@services/safe';

// assets
import SearchIcon from '@images/fly-safe-search-icon.svg';
import ArrowIcon from '@images/fly-safe-arrow.svg';
import CircleWhite from '@images/flysafe-circle-white.svg';
import CircleGreen from '@images/flysafe-circle-green.svg';

// utils
import { translate as t } from '@utils/translate';
import { getCurrentLang } from '@utils/language';

const Spinner = () => {
  return (
    <Styled.Spinner>
      <img src={CircleWhite} alt="circle white" className="spinner_white" />
      <img src={CircleGreen} alt="circle green" className="spinner_green" />
    </Styled.Spinner>
  );
};

const AircraftFeedback = ({
  hasPassengers,
  labelStatus,
  date,
  status,
  data,
}) => {
  const TEXTS = {
    flysafeRegistration: t('flysafeRegistration'),
    flysafePassengers: t('flysafePassengers'),
    flysafeDate: t('flysafeDate'),
  };

  return (
    <Styled.Feedback>
      <Styled.FeedbackDate>
        {TEXTS.flysafeDate} {date}
      </Styled.FeedbackDate>
      <Styled.FeedbackStatus isAuthorized={status}>
        {labelStatus}
      </Styled.FeedbackStatus>

      {hasPassengers ? (
        <>
          <Styled.FeedbackLabel>
            {TEXTS.flysafeRegistration}
            <Styled.FeedbackValue>
              {data?.registrationNumber}
            </Styled.FeedbackValue>
          </Styled.FeedbackLabel>

          <Styled.FeedbackLabel>
            {TEXTS.flysafePassengers}
            <Styled.FeedbackValue>
              {data.passengers} {TEXTS.flysafePassengers}
            </Styled.FeedbackValue>
          </Styled.FeedbackLabel>
        </>
      ) : null}
    </Styled.Feedback>
  );
};

const OperatorFeedback = ({ labelStatus, date, status, data }) => {
  const TEXTS = {
    flysafeRegistration: t('flysafeRegistration'),
    flysafePassengers: t('flysafePassengers'),
    flysafeDate: t('flysafeDate'),
    operatorNotFound: t('operatorNotFound'),
    authorized: t('authorized'),
    notAuthorized: t('notAuthorized'),
    status: t('status'),
    corporateName: t('corporateName'),
  };

  return data.status != 404 ? (
    <Styled.Feedback>
      <Styled.FeedbackDate>
        {TEXTS.flysafeDate} {date}
      </Styled.FeedbackDate>
      <Styled.FeedbackStatus isAuthorized={status}>
        {labelStatus}
      </Styled.FeedbackStatus>

      <Styled.FeedbackLabel>
        {TEXTS.corporateName}
        <Styled.FeedbackValue>{data?.corporateName}</Styled.FeedbackValue>
      </Styled.FeedbackLabel>

      <Styled.StatusContainer>
        <Styled.FeedbackLabel>
          CNPJ
          <Styled.FeedbackValue>{data.cnpj}</Styled.FeedbackValue>
        </Styled.FeedbackLabel>

        <Styled.FeedbackLabel style={{ marginLeft: 40 }}>
          {TEXTS.status}
          <Styled.FeedbackValue>
            {status ? TEXTS.authorized : TEXTS.notAuthorized}
          </Styled.FeedbackValue>
        </Styled.FeedbackLabel>
      </Styled.StatusContainer>
    </Styled.Feedback>
  ) : (
    <Styled.Feedback>
      <Styled.FeedbackDate>
        {TEXTS.flysafeDate} {date}
      </Styled.FeedbackDate>
      <Styled.FeedbackStatus isAuthorized={false}>
        {TEXTS.operatorNotFound}
      </Styled.FeedbackStatus>
    </Styled.Feedback>
  );
};

const FormFlyLegal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [isOperator, setIsOperator] = useState(false);

  const language = getCurrentLang();

  const { handleSubmit, errors, control, reset } = useForm({
    mode: 'onSubmit',
  });

  const TEXTS = {
    required: t('requiredField'),
    search: t('search'),
    searching: t('searching'),
    flysafePrivateJetAuthorized: t('flysafePrivateJetAuthorized'),
    flysafePrivateJetNonAuthorized: t('flysafePrivateJetNonAuthorized'),
    flysafeDate: t('flysafeDate'),
    flysafeRegistration: t('flysafeRegistration'),
    flysafePassengers: t('flysafePassengers'),
    aircraftNotFound: t('aircraftNotFound'),
    operator: t('operator'),
    flyLegalPlaceholderOperator: t('flyLegalPlaceholderOperator'),
  };

  const SCHEMA = {
    code: {
      rules: {
        required: {
          value: true,
          message: TEXTS.required,
        },
        validate: val => {
          return val.trim().length || TEXTS.required;
        },
      },
    },
  };

  const hasPassengers = data.passengers;

  useEffect(() => {
    reset();
  }, [language]);

  useEffect(() => {
    setData({});
  }, [isOperator]);

  const onSubmit = async data => {
    setIsLoading(true);
    const code = data.code.toUpperCase();

    try {
      let responseData = null;

      if (isOperator) {
        const { data } = await checkCompanyAuthorization(code);
        responseData = data;
      } else {
        const { data } = await checkAircraftAuthorization(code);
        responseData = data;
      }
      setData(responseData);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getDate = () => {
    const langs = {
      pt_BR: ptBR,
      en_US: enUS,
      es_ES: es,
    };

    return format(new Date(), 'PPPppp', {
      locale: langs[language],
    });
  };

  const getStatus = () => {
    if (isOperator) {
      return data.isAuthorized ? 1 : 0;
    }

    return data.passengers ? (data.isAuthorized ? 1 : 0) : 0;
  };

  const getLabelStatus = () => {
    if (isOperator) {
      return data.isAuthorized
        ? TEXTS.flysafePrivateJetAuthorized
        : TEXTS.flysafePrivateJetNonAuthorized;
    }

    return hasPassengers
      ? data.isAuthorized
        ? TEXTS.flysafePrivateJetAuthorized
        : TEXTS.flysafePrivateJetNonAuthorized
      : TEXTS.aircraftNotFound;
  };

  const hasData = !!Object.keys(data).length;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Styled.ContainerForm>
        <Styled.FlexContainer>
          <Styled.Label>
            <Styled.Icon src={SearchIcon} alt="search icon" />

            <Controller
              name="code"
              as={Styled.Input}
              control={control}
              defaultValue={''}
              errors={errors}
              placeholder={
                !isOperator
                  ? language === 'en_US'
                    ? 'Ex: PP-WSR'
                    : 'Ex: PP-NRJA'
                  : TEXTS.flyLegalPlaceholderOperator
              }
              {...SCHEMA.code}
            />
            {errors.code?.message ? (
              <Styled.ErrorForm>{errors.code?.message}</Styled.ErrorForm>
            ) : null}
          </Styled.Label>

          <Styled.Operator>
            <Styled.Scale value="0.8">
              <InputToggle
                value={isOperator}
                setValue={setIsOperator}
                color={'#33e0ce'}
              />
            </Styled.Scale>
            <Styled.Text>{TEXTS.operator}</Styled.Text>
          </Styled.Operator>

          <Styled.Button type="submit">
            {isLoading ? (
              <>
                <div className="searching_label">{TEXTS.searching}...</div>
                <Spinner />
              </>
            ) : (
              <>
                {TEXTS.search}
                <Styled.IconButton src={ArrowIcon} alt="arrow icon" />
              </>
            )}
          </Styled.Button>
        </Styled.FlexContainer>

        {hasData ? (
          isOperator ? (
            <OperatorFeedback
              hasPassengers={hasPassengers}
              labelStatus={getLabelStatus()}
              date={getDate()}
              status={getStatus()}
              data={data}
            />
          ) : (
            <AircraftFeedback
              hasPassengers={hasPassengers}
              labelStatus={getLabelStatus()}
              date={getDate()}
              status={getStatus()}
              data={data}
            />
          )
        ) : null}
      </Styled.ContainerForm>
    </form>
  );
};

export default FormFlyLegal;
