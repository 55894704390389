import useRequest from '@utils/useRequest';
import { checkAuthorization, company, apiHost } from '@utils/constants';

/**
 * used to verify if a aircraft is authorized or not
 * @param { string } code
 */
const checkAircraftAuthorization = code => {
  const url = apiHost + checkAuthorization + `?registrationNumber=${code}`;

  return useRequest({
    url: url,
    method: 'get',
    useSession: false,
  });
};

/**
 * used to verify if a company is authorized or not
 * @param {string} company
 */
const checkCompanyAuthorization = companyCode => {
  const url = apiHost + company + `?company=${companyCode}`;

  return useRequest({
    url: url,
    method: 'get',
    useSession: false,
  });
};

export { checkAircraftAuthorization, checkCompanyAuthorization };
