import styled from 'styled-components';
import flyBg from '@images/fly-safe-bg.png';

const FlyLegalWrapper = styled.div`
  position: relative;
  /* margin-bottom: 400px; */

  margin-top: 40px;

  @media screen and (min-width: 1024px) {
    margin-top: 80px;
    margin-bottom: -120px;
  }
`;

const Container = styled.div`
  width: 100%;
  min-height: 800px;
  height: auto;
  position: relative;
  background-image: url(${flyBg});
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  color: white;
  padding: 10px 20px 0 20px;

  @media screen and (min-width: 1024px) {
    padding: 20px 40px 0 40px;
  }
`;

const Retangle = styled.div`
  height: 500px;
  width: 70%;
  background-color: #00bfa5;
  position: relative;
  bottom: 200px;
  z-index: -1;
  border-radius: 0 0 300px 0;
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 1100px;
`;

const Title = styled.h1`
  /* color: #00bfa5; */

  font-size: 2rem;

  @media screen and (min-width: 1024px) {
    font-size: 3rem;
  }
`;

const Subtitle = styled.h2`
  font-weight: 500;
  margin-top: 29px;
  font-size: 1.4rem;

  @media screen and (min-width: 1024px) {
    font-size: 2rem;
  }
`;

const Text = styled.p`
  margin-top: 56px;
  font-weight: 300;
  font-size: 1rem;

  @media screen and (min-width: 1024px) {
    font-size: 1.5rem;
  }
`;

export { Container, Content, Title, Subtitle, Text, Retangle, FlyLegalWrapper };
